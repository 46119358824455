import React from 'react';

import { Btn } from './btn';
import { HeroWrapper } from './styles';

const Hero = () => {
  return (
    <HeroWrapper>
      <div className="hero"></div>
      <div className="container">
        <div>
          <h2>Um olhar regional </h2>
          <h2>para transformação da sua imobiliária</h2>
        </div>
        <div>
          <small>Desconto no 1º lote</small>
          <Btn
            as="a"
            href="https://scheneventos.com.br/Credpago"
            className="tooltip"
            target="_blank"
            data-tooltip="Esse link redireciona para um endereço externo, portanto fora da CredPago, com suas próprias políticas e controles de segurança."
            // onClick={e => {
            //   e.preventDefault()
            //   document
            //     .querySelector("#registrar-interesse")
            //     .scrollIntoView({ behavior: "smooth" })
            // }}
          >
            <svg width="16" height="20" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.91912 23.4768C5.27206 23.2195 2.58824 21.9327 1.26471 19.6165C-0.0220556 17.3003 0.308827 14.3592 1.22794 11.8224L2 14.0283C2.07353 14.2489 2.18383 14.5062 2.36765 14.6165C2.66177 14.8003 3.06618 14.6533 3.28677 14.3592C3.47059 14.065 3.50736 13.6974 3.47059 13.3665C3.3603 11.8959 2.51471 10.5356 2.47794 9.06504C2.44118 7.37387 3.50736 5.82975 4.83088 4.76357C4.86765 5.35181 5.01471 5.97681 5.19853 6.52828C5.27206 6.74886 5.41912 6.96945 5.63971 7.00622C5.78677 7.00622 5.93383 6.93269 6.04412 6.82239C7.14706 5.90328 7.80883 4.57975 8.06618 3.18269C8.17647 2.48416 8.17647 1.74886 8.02941 1.05034C7.99265 0.903277 7.69853 0.167983 7.77206 0.0576884C7.88236 -0.1629 8.50736 0.315041 8.65442 0.388571C11.0809 1.93269 12.6985 4.69004 12.7353 7.55769C12.7353 7.99887 12.8088 8.5871 13.25 8.66063C13.5074 8.69739 13.7647 8.55034 13.9118 8.32975C14.0588 8.10916 14.0588 7.85181 14.0956 7.59445C15.8235 9.13857 17.0735 11.0503 17.9191 13.1827C18.3971 14.4327 18.7279 15.793 18.4706 17.1165C18.25 18.3297 17.6618 19.4327 16.8162 20.3518C15.4191 21.9327 13.5441 23.1827 11.4485 23.4033C10.3456 23.5136 9.16912 23.5503 7.91912 23.4768Z"
                fill="#F95353"
              />
            </svg>
            GARANTA SEU INGRESSO
          </Btn>
        </div>
      </div>
    </HeroWrapper>
  );
};

export default Hero;
