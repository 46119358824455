import React from 'react';
import { Helmet } from 'react-helmet';

import UI from '#front-credpago';

import About from './about';
import Footer from './footer';
import Hero from './hero';
import NextEvent from './nextEvent';
import PhotoCarrousel from './photoCarrousel';
import Pillars from './pillars';
import { Container, HeaderContainer } from './styles';
import Testimonial from './testimonial';

const OnTheRoad = ({ data }) => {
  data.date = '2022-08-18 08:00:00';
  data.city = 'Brasília';
  data.uf = 'DF';
  data.place = 'FIERGS';
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/styles/fonts.css" />
        <meta name="adopt-website-id" content="78ef1068-840c-4ada-af0d-2d7f81f20890" />
        <script
          src="//tag.goadopt.io/injector.js?website_code=78ef1068-840c-4ada-af0d-2d7f81f20890"
          className="adopt-injector"
        ></script>
        <style>
          {`
        .adopt-controller-button {
          bottom: 90px!important;
        }
          @media (max-width: 768px) {
            .adopt-controller-button {
              bottom: 160px!important;
              left: 8px!important;
              width: 50px !important!important;
              height: 50px !important!important;
            }
          }
        `}
        </style>
      </Helmet>
      <UI.GlobalStyle />
      <HeaderContainer>
        <Container className="header">
          <nav>
            <ul>
              <li>
                <a
                  href="#o-que-e"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#o-que-e').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  O que é
                </a>
              </li>
              <li>
                <a
                  href="#onde-acontece"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#onde-acontece').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Onde acontece
                </a>
              </li>
              <li>
                <a
                  href="#depoimentos"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#depoimentos').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Depoimentos
                </a>
              </li>
              <li>
                <a
                  href="#palestrantes"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#palestrantes').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Palestrantes
                </a>
              </li>
              <li>
                <a
                  href="#pilares"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#pilares').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Pilares do OTR
                </a>
              </li>
              <li>
                <a
                  href="#ingressos"
                  onClick={(e) => {
                    e.preventDefault();
                    document.querySelector('#ingressos').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Ingressos
                </a>
              </li>
              {/* <li>
                <a
                  href="#happy-hour"
                  onClick={e => {
                    e.preventDefault()
                    document
                      .querySelector("#happy-hour")
                      .scrollIntoView({ behavior: "smooth" })
                  }}
                >
                  Happy Hour
                </a>
              </li> */}
            </ul>
          </nav>
        </Container>
      </HeaderContainer>
      <Hero />
      <NextEvent data={data} />
      <Testimonial />
      <About />
      {/* <Speeches /> */}
      <PhotoCarrousel />
      {/* <Schedule data={data} /> */}
      <Pillars />
      {/* <HappyHour /> */}
      {/* <Ticket /> */}
      <Footer data={data} />
    </>
  );
};

export default OnTheRoad;
