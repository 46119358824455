import React from 'react';

import clock from '../../../images/on-the-road/d2/clock.svg';
import { Airplane, Ticket } from './icon';
import { NextEventWrapper } from './styles';

const NextEvent = ({ data }) => {
  return (
    <NextEventWrapper>
      <div className="a" id="onde-acontece">
        <div className="b">
          <div className="container">
            <div>
              <div className="airplane-anime">
                <Airplane color="white" width="63" height="45" />
              </div>
              <h3>Próximo Encontro</h3>
              <h4>
                {data.city} - {data.uf}
              </h4>
              <p>
                Chegou sua tão aguardada chance de sair do piloto automático com um evento imobiliário focado na sua
                região. A segunda edição do
                <b>
                  {' '}
                  <i>On The Road</i>
                </b>{' '}
                CredPago desembarca no Capital do Brasil trazendo grandes speakers do setor para compartilhar o que é
                relevante para o mercado do Centro-Oeste do Brasil.
              </p>
              <Ticket city={data.city} date={data.date} place={data.place} />
              <div className="abertura">
                <img src={clock} alt="clock" />
                Abertura do credenciamento às 8:00 horas.
              </div>
            </div>
          </div>
        </div>
      </div>
    </NextEventWrapper>
  );
};

export default NextEvent;
