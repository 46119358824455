import React from 'react';

import { CountdownWrapper } from './styles';
import { useCountdown } from './useCountdown';

const DateTimeDisplay = ({ value, type }) => {
  const str = value.toString().padStart(2, '0').split('');
  return (
    <div>
      <div className="date">
        <div>{str[0]}</div>
        <div>{str[1]}</div>
      </div>
      <div>{type}</div>
    </div>
  );
};

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <CountdownWrapper>
      {days !== 0 && (
        <>
          <DateTimeDisplay value={days} type={'DIAS'} />
          <div className="p2">:</div>
        </>
      )}
      <DateTimeDisplay value={hours} type={'HORAS'} />
      <div className="p2">:</div>
      <DateTimeDisplay value={minutes} type={'MINUTOS'} />
      <div className="p2">:</div>
      <DateTimeDisplay value={seconds} type={'SEGUNDOS'} />
    </CountdownWrapper>
  );
};

const Countdown = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />;
  }
};
export default Countdown;
