import { graphql } from 'gatsby';
import React from 'react';

import OnTheRoad from '../components/partials/onTheRoad';
import SEO from '../components/seo';

export const query = graphql`
  query {
    Hero: file(relativePath: { eq: "on-the-road/hero-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    StaticCityPoa: file(relativePath: { eq: "on-the-road/destaque-poa.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 537, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ScheduleDesktop: file(relativePath: { eq: "on-the-road/schedule/desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ScheduleMobile: file(relativePath: { eq: "on-the-road/schedule/mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 520, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

const OnTheRoadPage = ({ data }) => {
  return (
    <>
      <SEO title="CredPago On The Road" />
      <OnTheRoad data={data} />
    </>
  );
};

export default OnTheRoadPage;
