import React from 'react';

import { AboutWrapper } from './styles';

const About = () => {
  return (
    <AboutWrapper>
      <div className="container" id="o-que-e">
        <div>
          <h5>Um olhar regional para transformação da sua imobiliária</h5>
          <p>
            A era do consumo remoto, da comunicação em tempo real e do rápido retorno sobre o investimento acelerou a
            digitalização das imobiliárias. Mas não significa que um case de sucesso em determinada imobiliária
            funcionará para todo o país. Por essa razão,
            <strong>
              {' '}
              a proposta do <i>On The Road</i> é levar até você uma abordagem regionalizada
            </strong>{' '}
            sobre o seu foco de atuação.
          </p>
        </div>
      </div>
    </AboutWrapper>
  );
};

export default About;
