import React from 'react';

import brunoGamaImg from '../../../images/on-the-road/brasilia/bruno_gama.jpg';
import carlosNogueiraImg from '../../../images/on-the-road/brasilia/carlos_nogueira.jpg';
import diegoMoellerImg from '../../../images/on-the-road/brasilia/diego_moeller.jpg';
import painelLoftImg from '../../../images/on-the-road/brasilia/painel_loft.jpg';
import tandeImg from '../../../images/on-the-road/brasilia/tande.jpg';
import { Carousel } from './carousel';
import { Container, PhotoCarrouselWrapper } from './styles';

const slides = [
  {
    image: tandeImg,
    name: 'Tande',
    top_description: 'Campeão olímpico e empreendedor',
    bottom_description: '',
  },
  {
    image: carlosNogueiraImg,
    name: 'Carlos Nogueira',
    top_description: 'Diretor comercial CredPago/Grupo Loft',
    bottom_description: 'Raio x das Imobis do Brasil: contexto e novas perspectivas',
  },
  {
    image: brunoGamaImg,
    name: 'Bruno Gama',
    top_description: 'Founder e CEO CrediHome',
    bottom_description: 'Financiamento imobiliário e home equity',
  },
  {
    image: diegoMoellerImg,
    name: 'Diego Moeller',
    top_description: 'Co Founder e CCO da Captei',
    bottom_description: 'Desafios da captação de imóveis',
  },
  {
    image: painelLoftImg,
    name: 'Painel - Grupo Loft',
    top_description: 'CredPago / Loft / Vista / CrediHome',
    bottom_description: 'Grupo Loft: O que vem por aí?',
  },
];

const PhotoCarrousel = () => {
  return (
    <PhotoCarrouselWrapper>
      <Container className="container" id="palestrantes">
        <div>
          <div className="intro swiper-carousel">
            <h5>
              Conheça os palestrantes
              <br />
              <span>do On The Road - Brasília</span>
            </h5>
            <Carousel slides={slides} />
          </div>
        </div>
      </Container>
    </PhotoCarrouselWrapper>
  );
};

export default PhotoCarrousel;
