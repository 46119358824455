import styled from 'styled-components';

export const IconAirplaneWrapper = styled.div`
  position: relative;
  margin-bottom: 1em;
  display: ${(props) => (props.display ? props.display : 'block')};
  &:before {
    content: '';
    width: 35vw;
    height: 2px;
    background: ${(props) => (props.color ? props.color : 'white')};
    display: block;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translate3d(-30px, -1px, 0);
  }
`;

export const IconSpeakerWrapper = styled.div`
  position: relative;
  .profile :first {
    div:nth-child(3) {
      margin-top: 20px;
    }
  }
  .profile {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px 10px;
    font-size: 15px;
    color: #003863;
    font-weight: 500;
    font-family: 'Uni Neue', Roboto, sans-serif;
    > div:nth-child(1) {
      font-weight: 800;
      font-size: 17px;
      letter-spacing: 0px;
      text-align: center;
      line-height: 1;
      margin-top: 10px;
    }
    > div:nth-child(2) {
      font-weight: 600;
      font-size: 17px;
      text-align: center;
      line-height: 1.1;
    }
    > div:nth-child(3) {
      text-align: center;
    }
    > div:nth-child(3) img {
      border-radius: 4px;
      width: 154px;
      height: 154px;
      margin-top: 10px;
    }
    > div:nth-child(4) {
      text-align: center;
      font-weight: 600;
    }
    > div:nth-child(5) {
      text-align: center;
      line-height: 1.1;
      font-size: 13px;
    }
  }
  .description {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px;
    font-size: 15px;
    color: #003863;
    font-family: 'Uni Neue', Roboto, sans-serif;
    text-align: center;
    > div:nth-child(1) {
      padding-left: 20px;
      padding-top: 5px;
      margin-top: 5px;
      background-repeat: no-repeat;
      background-position: top left;
      font-weight: 700;
    }
    > div:nth-child(2) {
      font-size: 15px;
      margin-top: 10px;
      font-weight: 500;
      line-height: 1.3;
    }
  }
`;
export const IconPanelWrapper = styled.div`
  position: relative;
  @media screen and (max-width: 576px) {
    overflow-x: auto;
    padding-left: 1.3em;
    padding-right: 1.5em;
    svg {
      width: 600px !important;
    }
  }
`;

export const IconTicketWrapper = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  justify-self: center;
  justify-content: center;
  align-items: center;
  margin: 1em auto 0;
  @media screen and (max-width: 576px) {
    grid-template-columns: 100%;
    justify-items: center;
  }
  @media screen and (max-width: 768px) {
  }
  div:nth-child(1) {
    justify-content: center;
    align-items: center;
    display: grid;
    svg {
      width: 150px;
    }
    @media screen and (max-width: 576px) {
      justify-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }
  }
  div:nth-child(2) {
    display: grid;
  }
  h2 {
    font-family: 'Uni Neue';
    font-style: italic;
    font-weight: 800;
    margin-right: 1em;
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 20px;
    @media screen and (max-width: 576px) {
      font-size: 15px;
      line-height: 1.3;
      text-align: center;
      margin-left: 0;
    }
    &:nth-child(1) span {
      color: #00ecff;
    }
    &:nth-child(2) {
    }
  }
  a {
    color: #fff;
    text-align: center;
    font-size: 13px;
    text-decoration: none;
  }
  @media screen and (min-width: 576px) {
    .tooltip {
      display: inline;
      position: relative;
    }
    .tooltip:hover:before {
      border: solid;
      border-color: #001c4a transparent;
      border-width: 0px 6px 6px 6px;
      bottom: -8px;
      content: '';
      left: 43%;
      position: absolute;
      z-index: 99;
    }
    .tooltip:hover:after {
      background: #001c4a;
      bottom: -68px;
      border-radius: 5px;
      color: #fff;
      content: attr(data-tooltip);
      left: 0;
      padding: 5px 15px;
      position: absolute;
      z-index: 98;
      width: 318px;
      font-size: 14px;
      text-transform: none;
      font-weight: 300;
      line-height: 1.2;
    }
  }
`;
