import React from 'react';

import { TestimonialWrapper } from './styles';

const videos = [
  {
    nome: 'Erica Vitali',
    description: 'Auxiliadora Predial',
    src: 'https://www.youtube.com/embed/yET32h7234Q',
  },
  {
    nome: 'Erico Homem Ribeiroi',
    description: 'Auxiliadora Predial',
    src: 'https://www.youtube.com/embed/1YpOeEg10gY',
  },
  {
    nome: 'Arlene Gomes',
    description: 'Mentora de Gestores Imobiliários',
    src: 'https://www.youtube.com/embed/O-JohcXAv_8',
  },
  {
    nome: 'Arthur Mueller',
    description: 'Imobiliária Sperinde',
    src: 'https://www.youtube.com/embed/0exMaVAVhms',
  },
  {
    nome: 'Matheus Wild',
    description: 'Wild Imóveis',
    src: 'https://www.youtube.com/embed/3_Gqj4b6B98',
  },
];

const Testimonial = () => {
  return (
    <TestimonialWrapper>
      <div id="depoimentos">
        <h2>
          Veja a opinião de quem saiu do piloto automático <br />
          para levar conhecimento na bagagem.
        </h2>
        <div className="container">
          {videos.map((video) => {
            return (
              <div key={video.src}>
                <iframe
                  id="ytplayer"
                  type="text/html"
                  width="100%"
                  height="600px"
                  src={video.src}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            );
          })}
        </div>
      </div>
    </TestimonialWrapper>
  );
};

export default Testimonial;
