import styled from 'styled-components';

export const NextEventWrapper = styled.div`
  .expired-notice {
    text-align: center;
    padding: 2rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    margin: 0.5rem;
  }

  .expired-notice > span {
    font-size: 2.5rem;
    font-weight: bold;
    color: red;
  }

  .expired-notice > p {
    font-size: 1.5rem;
  }

  .show-counter {
    padding: 0.5rem;
  }

  .show-counter .countdown-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding: 0.5rem;
    border: 1px solid #ebebeb;
    border-radius: 0.25rem;
    text-decoration: none;
    color: #000;
  }

  .show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .show-counter .countdown.danger {
    color: #ff0000;
  }

  .show-counter .countdown > p {
    margin: 0;
  }

  .show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;

export const CountdownWrapper = styled.div`
  display: flex;
  .p2 {
    padding: 11px 6px;
  }
  > div {
    display: block;
    text-align: center;
    font-size: 11px;
    > div.date {
      grid-template-columns: 1fr 1fr;
      display: grid;
      grid-gap: 3px;
      margin-bottom: 3px;
      font-size: 16px;
      > div {
        font-family: 'Uni Neue';
        font-style: normal;
        font-weight: 700;
        background: linear-gradient(180deg, #595959 0%, #000000 100%);
        border-radius: 2px;
        padding: 8px;
        text-align: center;
        border-radius: 6px;
        background-color: #3e3e3e;
        min-width: 30px;
        @media screen and (max-width: 576px) {
          padding: 5px;
          min-width: 25px;
        }
      }
    }
  }
`;
