import styled from 'styled-components';

import brasiliaLogoSVG from '../../../images/on-the-road/brasilia/brasilia_logo.png';
import d1bg from '../../../images/on-the-road/d1/bg.jpg';
import d1img from '../../../images/on-the-road/d1/img.png';
import d1logo from '../../../images/on-the-road/d1/logo.svg';
import d2seta1 from '../../../images/on-the-road/d2/seta1.png';
import d2seta2 from '../../../images/on-the-road/d2/seta2.png';
import d3img from '../../../images/on-the-road/d3/img.png';
import d5img from '../../../images/on-the-road/d5/img.png';
import d6bg1 from '../../../images/on-the-road/d6/bg1.png';
import d6bg2 from '../../../images/on-the-road/d6/bg2.svg';
import d7img from '../../../images/on-the-road/d7/img.jpg';
import d7selo from '../../../images/on-the-road/d7/selo.svg';
import d8img from '../../../images/on-the-road/d8/img.png';
import d8ind from '../../../images/on-the-road/d8/ingresso_individual.png';
import globals from '../../globals';

const { fonts } = globals;

export const Container = styled.div``;
export const HeaderContainer = styled.div`
  width: 100%;
  display: block;
  background: rgba(255, 255, 255, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  backdrop-filter: blur(6px);

  .visually-hidden {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
    clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
    clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
    white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
  }

  .header {
    display: flex;
    padding: 0.6em 15px;
    max-width: 750px;
    margin: 0 auto;
    @media screen and (max-width: 576px) {
      display: none;
    }
    h1 {
      align-self: center;
      margin: 0;
      padding: 0;
      transform: translateY(3px);
    }

    nav {
      margin: 0;
      align-self: center;
      flex-grow: 2;
      ul {
        list-style: none;
        display: flex;
        justify-content: center;
        grid-gap: 2em;
        li {
          display: inline-block;
          a {
            color: #023764;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
`;

export const HeroWrapper = styled.section`
  font-family: Uni Neue;
  font-size: 12px;
  font-style: italic;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  background: no-repeat left center/100% 100% url(${d1bg});
  min-height: 100vh;
  @media screen and (max-width: 576px) {
    min-height: 90vh;
  }
  .hero {
    background:
      no-repeat 55% 90px/10% url(${d1logo}),
      no-repeat center 120px/40% url(${d1img});
    min-height: 85vh;
    @media screen and (max-width: 768px) {
      background:
        no-repeat 55% 90px/20% url(${d1logo}),
        no-repeat center 140px/63% url(${d1img});
      min-height: 70vw;
    }
    @media screen and (max-width: 576px) {
      background:
        no-repeat 55% 90px/28% url(${d1logo}),
        no-repeat center 140px/75% url(${d1img});
      min-height: 93vw;
    }
    @media screen and (max-width: 350px) {
      background:
        no-repeat 55% 40px/28% url(${d1logo}),
        no-repeat center 80px/90% url(${d1img});
      min-height: 100vw;
    }
  }
  .container {
    display: grid;
    grid-template-columns: 500px 1fr;
    grid-gap: 4em;
    color: #023764;
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;
    @media screen and (min-width: 769px) and (max-width: 1200px) {
      grid-template-columns: 387px 1fr;
      padding-left: 2em;
      padding-right: 2em;
      grid-gap: 1em;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-gap: 0;
      justify-content: center;
      padding: 0 2em 2em;
    }
    @media screen and (max-width: 576px) {
      padding: 0 1.5em 1.5em;
    }
    h2 {
      font-family: 'Uni Neue';
      font-style: italic;
      font-weight: 800;
      font-size: 12px;
      line-height: 22px;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 0;
      font-size: 20px;
      letter-spacing: 1px;
      color: #ffffff;
      filter: drop-shadow(-0.279px 3.99px 2.5px rgba(8, 61, 174, 0.31));
      @media screen and (min-width: 769px) and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 768px) {
        &:nth-child(1) {
          font-weight: 800;
          font-size: 26px;
        }
        text-align: center;
        font-size: 25px;
        line-height: 35px;
      }
    }
    a {
      background-color: #ffcb05 !important;
      color: #023764 !important;
      width: 100%;
      display: block;
      margin-top: 3px;
      font-size: 1.4em;
      font-style: normal;
      padding: 10px 17px;
      @media screen and (max-width: 768px) {
        margin-top: 1em;
        width: 50%;
        margin-left: 10%;
      }
      @media screen and (max-width: 576px) {
        margin-top: 1em;
        width: 100%;
        margin-left: 0;
      }
    }
    small {
      font-size: 16px;
      color: #003863;
      font-weight: 500;
      text-align: center;
      @media screen and (min-width: 769px) and (max-width: 1200px) {
        font-size: 14px;
      }
    }
  }
  @media screen and (min-width: 576px) {
    .tooltip {
      display: inline;
      position: relative;
    }
    .tooltip:hover:before {
      border: solid;
      border-color: #001c4a transparent;
      border-width: 0px 6px 6px 6px;
      bottom: -8px;
      content: '';
      left: 10%;
      position: absolute;
      z-index: 99;
    }
    .tooltip:hover:after {
      background: #001c4a;
      bottom: -50px;
      border-radius: 5px;
      color: #fff;
      content: attr(data-tooltip);
      left: 0;
      padding: 5px 15px;
      position: absolute;
      z-index: 98;
      width: 100%;
      font-size: 14px;
      text-transform: none;
      font-weight: 300;
      line-height: 1.2;
    }
  }
`;

export const NextEventWrapper = styled.section`
  @keyframes go-right-next {
    0% {
      transform: translateX(-200px) rotate(25deg);
    }
    100% {
      transform: translateX(0px) rotate(25deg);
    }
  }
  background: url(${d2seta2});
  background-color: #010149;
  background-size: auto 35%;
  background-position: left 25% top 10%;
  background-repeat: no-repeat;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    min-height: auto;
  }
  div.a {
    background: url(${d2seta1});
    background-size: auto 35%;
    background-position: right 5% bottom 30%;
    background-repeat: no-repeat;
    div.b {
      background-image: url(${brasiliaLogoSVG});
      background-color: #01014980;
      background-size: 28% auto;
      background-repeat: no-repeat;
      background-position: right 25% center;
      @media screen and (max-width: 768px) {
        background-image: none;
      }
      .container {
        color: white;
        display: grid;
        grid-template-columns: 500px 1fr;
        padding: 6em 0em;
        max-width: 1024px;
        margin: 0 auto;
        min-height: 100vh;
        font-family: Roboto, sans-serif;
        font-style: normal;
        @media screen and (min-width: 769px) and (max-width: 1200px) {
          grid-template-columns: 387px 1fr;
          padding-left: 2em;
          padding-right: 2em;
        }
        @media screen and (max-width: 768px) {
          grid-template-columns: 100%;
          grid-gap: 0;
          justify-content: center;
          padding: 2em;
          min-height: auto;
        }
        @media screen and (max-width: 576px) {
          padding: 1.5rem;
        }
        align-self: center;
        h3,
        h4 {
          font-family: ${fonts.headings};
          font-style: italic;
          font-weight: 900;
          text-transform: uppercase;
          margin: 0;
          padding: 0;
          line-height: 1.1;
        }
        h3 {
          font-size: 2.2em;
          @media screen and (max-width: 768px) {
            font-size: 1.3em;
          }
        }
        h4 {
          font-size: 2.7em;
          color: #00ecff;
          @media screen and (max-width: 768px) {
            font-size: 1.3em;
            margin-bottom: 0.5em;
          }
        }
        p {
          font-size: 17px;
          line-height: 1.5;
          font-weight: 300;
          text-align: justify;
          /* hyphens: auto; */
          @media screen and (max-width: 576px) {
            margin: 0;
            margin-bottom: 2em;
            line-height: 1.3;
          }
        }
        .abertura {
          margin-top: 15px;
          color: white;
          font-family: 'Uni Neue';
          font-style: italic;
          font-weight: 800;
          font-size: 15px;
          text-transform: uppercase;
          display: flex;
          img {
            margin-right: 10px;
            width: 28px;
          }
        }
        .airplane-anime {
          svg {
            animation: go-right-next 2s;
          }
        }
      }
    }
  }
`;

export const LastEventWrapper = styled.section`
  @keyframes go-right-next {
    0% {
      transform: translateX(-200px) rotate(25deg);
    }
    100% {
      transform: translateX(0px) rotate(25deg);
    }
  }
  background: url(${d2seta2});
  background-color: #010149;
  background-size: auto 35%;
  background-position: left 25% top 10%;
  background-repeat: no-repeat;
  @media screen and (max-width: 768px) {
    min-height: auto;
  }
  div.a {
    background: url(${d2seta1});
    background-size: auto 35%;
    background-position: right 5% bottom 30%;
    background-repeat: no-repeat;
    div.b {
      iframe {
        border-radius: 15px;
        margin-top: 4rem;
        @media screen and (max-width: 576px) {
          margin-top: 1rem;
          padding-bottom: 1rem;
        }
      }
      .container {
        color: white;
        display: grid;
        grid-template-columns: 319px 1fr;
        gap: 30px;
        padding: 3em 0 10em;
        max-width: 1024px;
        margin: 0 auto;
        font-family: Roboto, sans-serif;
        font-style: normal;
        @media screen and (min-width: 769px) and (max-width: 1200px) {
          grid-template-columns: 387px 1fr;
          padding-left: 2em;
          padding-right: 2em;
        }
        @media screen and (max-width: 768px) {
          grid-template-columns: 100%;
          grid-gap: 0;
          justify-content: center;
          padding: 2em;
          min-height: auto;
        }
        @media screen and (max-width: 576px) {
          padding: 1.5rem;
        }
        align-self: center;
        h3,
        h4 {
          font-family: ${fonts.headings};
          font-style: italic;
          font-weight: 900;
          margin: 0;
          padding: 0;
          line-height: 1.1;
          span {
            color: #00ecff;
          }
        }
        h3 {
          font-size: 2.2em;
          @media screen and (max-width: 768px) {
            font-size: 1.3em;
          }
        }
        h4 {
          font-size: 2.7em;
          color: #00ecff;
          @media screen and (max-width: 768px) {
            font-size: 1.3em;
            margin-bottom: 0.5em;
          }
        }
        p {
          font-size: 17px;
          line-height: 1.5;
          font-weight: 300;
          text-align: justify;
          /* hyphens: auto; */
          @media screen and (max-width: 576px) {
            margin: 0;
            line-height: 1.3;
          }
        }
        div {
          margin: 2em 0;
          @media screen and (max-width: 576px) {
            margin: 0;
          }
        }
        .abertura {
          margin-top: 15px;
          color: white;
          font-family: 'Uni Neue';
          font-style: italic;
          font-weight: 800;
          font-size: 15px;
          text-transform: uppercase;
          display: flex;
          img {
            margin-right: 10px;
            width: 28px;
          }
        }
        .airplane-anime {
          svg {
            animation: go-right-next 2s;
          }
        }
      }
    }
  }
`;

export const TestimonialWrapper = styled.section`
  background: linear-gradient(222.29deg, #016aae 23.81%, #31ccff 109.69%);
  padding: 2em 0em 2em;
  min-height: 100vh;
  h2 {
    font-family: ${fonts.headings};
    font-size: 2.2em;
    font-weight: 900;
    font-style: italic;
    text-align: center;
    margin: 0 0 2em;
    color: #fff;
    @media screen and (max-width: 576px) {
      font-size: 1.3em;
      margin-bottom: 1em;
    }
  }
  .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
    color: #023764;
    max-width: 1024px;
    margin: 0 auto;
    text-align: center;
    @media screen and (min-width: 769px) and (max-width: 1200px) {
      grid-template-columns: 387px 1fr;
      padding-left: 2em;
      padding-right: 2em;
      grid-gap: 1em;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-gap: 0;
      justify-content: center;
      padding: 0 2em 2em;
    }
    @media screen and (max-width: 576px) {
      padding: 0 1.5em 1.5em;
    }
    iframe {
      padding-bottom: 15px;
    }
  }
`;

export const AboutWrapper = styled.div`
  background-image: url(${d3img});
  background-size: 46% auto;
  background-repeat: no-repeat;
  background-position: right bottom;
  min-height: 100vh;
  @media screen and (max-width: 576px) {
    background-image: none;
    min-height: auto;
    padding: 0 !important;
  }
  @media screen and (max-width: 768px) {
    min-height: 100%;
    padding: 2em 0;
    background-size: 40% auto;
  }

  .container {
    max-width: 1024px;
    margin: 0 auto;
    color: #003863;
    display: grid;
    grid-template-columns: 500px 1fr;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    @media screen and (min-width: 576px) and (max-width: 1200px) {
      grid-template-columns: 387px 1fr;
      padding-left: 2em;
      padding-right: 2em;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      padding: 2em;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      padding: 1.5em;
    }
    h5 {
      font-family: ${fonts.headings};
      font-weight: 900;
      font-style: italic;
      font-size: 3.2em;
      margin-top: 2em;
      margin-bottom: 0.5em;
      @media screen and (min-width: 769px) and (max-width: 1200px) {
        font-size: 3.1em;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.9em;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    p {
      font-size: 17px;
      line-height: 1.5;
      text-align: justify;
      /* hyphens: auto; */
      @media screen and (max-width: 768px) {
        margin-bottom: 0;
        line-height: 1.3;
      }
      i {
        color: #20edff;
      }
    }
  }
`;

export const PillarsWrapper = styled.div`
  .container {
    background: #e3f3fb;
    padding-bottom: 4em;
    padding-top: 4em;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 15px;
    @media screen and (max-width: 768px) {
      padding-bottom: 0;
      padding-top: 2em;
    }
    @media screen and (max-width: 768px) {
      padding: 2em 0;
    }
  }
  h5 {
    font-family: ${fonts.headings};
    font-size: 3.2em;
    font-weight: 900;
    font-style: italic;
    text-align: center;
    margin: 0 0 1em;
    color: #003863;
    @media screen and (max-width: 576px) {
      font-size: 1.8em;
      margin-bottom: 0;
    }
    i {
      color: #20edff;
    }
  }
  .pillars {
    margin: 0 auto;
    max-width: 1024px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4em;
    color: #113c64;
    text-align: justify;
    /* hyphens: auto; */
    @media screen and (min-width: 769px) and (max-width: 1200px) {
      padding: 0 2em;
      grid-gap: 2em;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-gap: 0;
      padding: 2em;
    }
    @media screen and (max-width: 576px) {
      padding: 1.5em;
    }
    .icon-wrapper {
      max-width: 50px;
      margin: 0 auto 1em;
      display: block;
      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
    h6 {
      text-align: center;
      text-transform: uppercase;
      font-size: 1.5em;
      font-family: ${fonts.headings};
      font-weight: 600;
      @media screen and (min-width: 769px) and (max-width: 1200px) {
        margin: 1em 0;
      }
      @media screen and (max-width: 768px) {
        margin: 0 0 0.5em;
      }
    }
    p {
      line-height: 1.5;
      @media screen and (max-width: 768px) {
        &:last-child {
          margin: 0 0 2em;
        }
      }
      @media screen and (max-width: 576px) {
        line-height: 1.3;
        font-size: 17px;
      }
    }
  }
  .pillars-container {
    background-image: url(${d5img});
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 48%;
    height: 100vh;
    margin-top: -23vh;
    padding-top: 23vh;
    margin-bottom: 40vh;
    font-size: 17px;
    line-height: 1.5;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    color: #003863;
    @media screen and (min-width: 769px) and (max-width: 1200px) {
      margin-top: -15vh;
      margin-bottom: 20vh;
    }
    @media screen and (max-width: 768px) {
      margin-top: -12vh;
      background-size: 53%;
      margin-bottom: 30vh;
    }
    @media screen and (max-width: 576px) {
      margin-top: -12vh;
      background-size: 75%;
      height: 100%;
    }

    > div {
      display: grid;
      grid-template-columns: 500px 1fr;
      margin: 0 auto;
      max-width: 1024px;
      align-items: center;
      min-height: 100vh;
      padding-bottom: 9em;
      @media screen and (min-width: 769px) and (max-width: 1200px) {
        grid-template-columns: 387px 1fr;
        align-items: center;
        padding: 0 2em;
      }
      @media screen and (max-width: 768px) {
        grid-template-columns: 100%;
        align-items: center;
        padding: 2em;
        min-height: auto;
      }
      @media screen and (max-width: 576px) {
        padding: 1.5em;
      }
      p {
        line-height: 1.3;
        text-align: justify;
        /* hyphens: auto; */
        @media screen and (max-width: 768px) {
          width: 150%;
        }
      }
      .pillars-img-logo {
        width: 60%;
        @media screen and (max-width: 768px) {
          margin-top: 30px;
          width: 90%;
        }
      }
      .pillars-next-event {
        background-color: #ffcb05;
        padding: 1em 1.5em;
        border-radius: 12px;
        margin-top: 1em;
        margin-bottom: 2em;
        @media screen and (max-width: 576px) {
          padding: 1em;
        }
        h3 {
          font-family: 'Uni Neue';
          font-style: italic;
          font-weight: 800;
          font-size: 27px;
          line-height: 29px;
          text-transform: uppercase;
          color: #003863;
          margin: 0;
          @media screen and (max-width: 576px) {
            font-size: 22px;
          }
        }
        b {
          font-family: 'Uni Neue';
          font-style: italic;
          font-size: 17px;
          font-weight: 800;
          @media screen and (max-width: 576px) {
            font-size: 14px;
          }
        }
        .pillars-next-event-header {
          display: grid;
          grid-template-columns: 3fr 1fr;
          @media screen and (max-width: 576px) {
            grid-template-columns: 100%;
            img {
              display: none;
            }
          }
        }
        form {
          input {
            width: 100%;
            padding: 0.6em;
            color: #005bcb;
            border-radius: 8px;
            margin-top: 15px;
            border: 0;
          }
          input[aria-invalid='false'] {
            border: 1px solid white;
          }
          input[aria-invalid='true'] {
            border: 1px solid red;
          }
          label[aria-invalid='true'] {
            color: red !important;
            a {
              color: red !important;
            }
          }
          button {
            min-height: 36px !important;
            font-size: 1em !important;
            padding: 7px 8px !important;
            border-radius: 4px !important;
            margin-top: 15px;
            border: 1px solid #ffcb05;
            color: white;
            background: #02399b;
            border-radius: 8px !important;
          }
          .footer {
            font-size: 13px;
            input {
              width: 15px;
              margin-right: 5px;
            }
            a {
              color: #1a365d;
            }
            label {
              color: rgba(0, 0, 0, 0.6);
              margin-top: 10px;
              text-align: justify;
            }
            div:nth-child(2) {
              text-align: right;
            }
          }
        }
        .sent {
          font-family: 'Uni Neue';
          min-height: 201px;
          align-items: center;
          display: grid;
          font-weight: 500;
          text-align: center;
          h3 {
            font-weight: 800;
            font-size: 20px;
          }
        }
        .sent-footer {
          text-align: right;
          button {
            font-size: 13px;
            min-height: 36px !important;
            padding: 7px 8px !important;
            border-radius: 4px !important;
            margin-top: 15px;
            border: 1px solid #ffcb05;
            color: white;
            background: #02399b;
            border-radius: 8px !important;
          }
        }
        @media screen and (max-width: 576px) {
          width: 135%;
        }
        @media screen and (max-width: 768px) {
          width: 150%;
        }
      }
      .pillars-map {
        margin-left: -20%;
        margin-top: 80px;
        position: relative;
        z-index: -1;
        @media screen and (min-width: 769px) and (max-width: 1200px) {
          margin-left: -35%;
          margin-top: 0px;
        }
        @media screen and (max-width: 768px) {
          background-image: none;
          margin-top: 0;
          margin-left: -50%;
          display: none;
        }
        img {
          margin-top: 50px;
          @media screen and (min-width: 769px) and (max-width: 1200px) {
            margin-top: 0px;
          }
          @media screen and (max-width: 768px) {
            margin-top: 0;
          }
        }
      }
      .pillars-event {
        position: relative;
      }
      .row {
        display: grid;
        margin: 0 auto;
        grid-template-columns: 100%;
        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        justify-items: left;
        align-items: top;

        @media screen and (max-width: 768px) {
          grid-template-columns: 100%;
          justify-items: center;
        }

        > div:first-child {
          padding-right: 10px;
          > div:nth-child(1) {
            padding-top: 25px;
            font-family: 'Uni Neue';
            font-style: italic;
            font-weight: 800;
            font-size: 28px;
            line-height: 24px;
            text-align: right;
            color: #003863;
            @media screen and (max-width: 576px) {
              font-size: 23px;
              text-align: center;
              line-height: 1.2;
              margin-top: 0px;
            }
          }
          > div:nth-child(2) {
            margin-top: 10px;
            font-family: 'Uni Neue';
            font-style: italic;
            font-weight: 400;
            font-size: 20px;
            line-height: 20px;
            text-align: right;
            color: #02cce6;
            @media screen and (max-width: 576px) {
              font-size: 23px;
              text-align: center;
              line-height: 1.2;
              margin-top: 0px;
            }
          }
        }
      }
      .col {
        text-decoration: none;
        background-repeat: no-repeat;
        background-position: center 0.5em;
        background-size: 94% auto;
        padding: 0.5em 0.7em;
        width: 260px;
        height: 489px;
        border-style: solid;
        border-width: 2px;
        border-color: transparent;
        margin-right: 0.5em;
        &:hover {
          text-decoration: none;
        }
        &.individual {
          background-image: url(${d8ind});
          @media screen and (max-width: 576px) {
            margin-left: 1.3em;
          }
          &:hover {
            filter: brightness(110%);
          }

          &:hover h1 img {
            filter: brightness(110%);
            zoom: 1.1;
          }
          &:hover h1:nth-child(3) {
            margin-top: 25px;
            @media screen and (max-width: 576px) {
              margin-top: 39px;
            }
          }

          &:hover h1:nth-child(1) {
            font-size: 2.1em;
            @media screen and (max-width: 576px) {
              font-size: 2.3em;
            }
          }

          button {
            background-color: #ffcb05 !important;
            color: #003994 !important;
            font-weight: bold !important;
            font-size: 1em !important;
            font-family: ${fonts.default} !important;
            margin-bottom: 0.5em;
            border: 0;
            padding: 4px 15px;
            text-align: center;
            width: 100%;
            &:hover {
              filter: brightness(105%);
            }

            &:active {
              filter: brightness(110%);
            }
          }
        }
        h1 {
          color: white;
          font-size: 2.5em;
          text-align: center;
          font-family: ${fonts.headings};

          @media screen and (max-width: 576px) {
            font-size: 1.7em;
          }

          &:nth-child(1) {
            margin-top: 30px;
            margin-bottom: 0px;
            font-size: 2em;
            font-weight: 500;

            @media screen and (max-width: 576px) {
              font-size: 1.6em;
            }
          }
          &:nth-child(2) {
            display: grid;
            align-items: center;
            justify-items: center;
            img {
              height: 110px;
            }
          }
          &:nth-child(3) {
            margin-top: 42px;
            margin-bottom: 28px;
            font-size: 1.5em;
            font-weight: 400;

            @media screen and (max-width: 576px) {
              font-size: 1.1em;
              margin-top: 68px;
              margin-bottom: 41px;
            }
          }
          &:nth-child(4) {
            color: black;
            font-size: 26px;
            margin: 0;
            display: grid;
            justify-items: center;
            > div {
              width: 120px;
              height: 1px;
              margin-top: 16px;
              border-bottom: 1px solid red;
              -webkit-transform: rotate(-11deg);
              position: absolute;
            }
          }
          &:nth-child(5) {
            font-style: normal;
            margin: 10px 0 25px;
            font-weight: 800;
            font-size: 40px;
            line-height: 19px;
            text-align: center;
            color: #02399b;
          }
        }
        h5 {
          font-family: 'Uni Neue', Roboto, sans-serif;
          font-size: 1.8em;
          font-weight: 900;
          font-style: italic;
          margin: 0 0 1.3em;
          color: #003863;
          max-width: 950px;
          margin: 0 auto 1em;
          @media screen and (max-width: 576px) {
            padding: 1.5rem;
            margin: 0 auto 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: 576px) {
    .tooltip {
      display: inline;
      position: relative;
    }
    .tooltip:hover:before {
      border: solid;
      border-color: transparent #001c4a;
      border-width: 6px 6px 6px 0px;
      bottom: 26px;
      content: '';
      left: 96%;
      position: absolute;
      z-index: 99;
    }
    .tooltip:hover:after {
      background: #001c4a;
      bottom: 8px;
      border-radius: 5px;
      color: #fff;
      content: attr(data-tooltip);
      left: 251px;
      padding: 5px 15px;
      position: absolute;
      z-index: 98;
      width: 201px;
      font-size: 14px;
      text-transform: none;
      font-weight: 300;
      line-height: 1.2;
    }
  }
`;

export const SpeechesWrapper = styled.section`
  background:
    repeat-x left 5%/60% url(${d6bg1}),
    no-repeat left center/100% url(${d6bg2}),
    linear-gradient(60deg, #005bcb 0%, #001c64 100%);
  color: white;
  min-height: 100vh;
  .container {
    margin: 0 auto;
    max-width: 1024px;
    padding: 8em 0em 2em;
    min-height: 100vh;
    font-size: 17px;
    line-height: 1.5;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    display: grid;
    grid-gap: 2em;
    grid-template-columns: 340px 1fr;
    @media screen and (max-width: 1200px) {
      align-items: center;
      grid-template-columns: 100%;
      min-height: auto;
      grid-gap: 0;
    }
    @media screen and (max-width: 576px) {
      padding: 0 !important;
    }
    @media screen and (max-width: 768px) {
      padding: 2em;
    }
    .intro {
    }
    h5 {
      font-family: ${fonts.headings};
      font-size: 2.4em;
      font-weight: 900;
      font-style: italic;
      margin: 0 0 1.3em;
      line-height: 1.3;
      text-align: center;
      @media screen and (max-width: 768px) {
        font-size: 2.2em;
        margin: 0 0 0.5em;
      }
      @media screen and (max-width: 576px) {
        font-size: 1.8em;
        padding: 1.5rem 1.5rem 0;
      }
    }
    p {
      text-align: justify;
      /* hyphens: auto; */
      @media screen and (max-width: 576px) {
        line-height: 1.3;
        padding: 0 1.5rem;
      }
      & + p {
        margin-top: 1.6em;
      }
    }
    .speaker {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0.3em;
      justify-self: center;
      padding-right: 18px;
      padding-left: 18px;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 202px);
        grid-gap: 0.2em;
        justify-content: center;
      }
      @media screen and (max-width: 576px) {
        overflow-x: auto;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: left;
      }
    }
    .panel {
      @media screen and (max-width: 1200px) {
        justify-self: center;
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 0;
      }
    }
  }
`;
export const PhotoCarrouselWrapper = styled.section`
  background: linear-gradient(180deg, #0056b9 0%, #00074f 100%);
  color: white;
  padding: 6em 6em;
  @media screen and (max-width: 576px) {
    padding: 1.5em 0 9em;
  }
  .swiper-carousel img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    border-radius: 8px;
    -webkit-box-reflect: below 5px - webkit-linear-gradient(
        bottom,
        rgba(255, 0, 0, 0.1) 0%,
        transparent 32px,
        transparent 100%
      );
  }
  h5 {
    font-family: ${fonts.headings};
    font-size: 3.2em;
    font-weight: 900;
    font-style: italic;
    text-align: center;
    margin: 0 0 2em;
    color: #fff;
    span {
      color: #20edff;
    }
    @media screen and (max-width: 576px) {
      font-size: 1.8em;
      margin-bottom: 0.5em;
    }
  }
`;

export const ScheduleWrapper = styled.section`
  padding: 6em 6em;
  background: linear-gradient(180deg, #32bdee 0%, #24378c 100%);

  @media screen and (max-width: 576px) {
    padding: 1.5em 0;
  }

  #programacao {
    max-width: 1600px;
    margin: 0 auto;
  }
`;

export const HHWrapper = styled.section`
  background:
    no-repeat 95% 83% / auto 170px url(${d7selo}),
    no-repeat right bottom/auto 100% url(${d7img});
  background-color: #051223;
  min-height: 91vh;
  color: white;
  @media screen and (max-width: 768px) {
    background: linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(4, 17, 38, 0.9) 30%, rgba(4, 17, 38, 1) 90%),
      url(${d7img});
    min-height: auto;
  }
  .container {
    align-self: center;
    margin: 0 auto;
    max-width: 1024px;
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-gap: 2em;
    align-items: center;
    min-height: 91vh;
    text-align: justify;
    /* hyphens: auto; */
    font-size: 17px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    @media screen and (min-width: 769px) and (max-width: 1200px) {
      padding: 0 2em;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      grid-gap: 0;
      padding: 2em;
      min-height: auto;
    }
    @media screen and (max-width: 576px) {
      padding: 1.5em;
    }
    > div {
      h4,
      h5 {
        font-family: ${fonts.headings};
        font-weight: 800;
        font-style: italic;
        margin: 0;
      }
      h4 {
        font-size: 2.8em;
        @media screen and (max-width: 576px) {
          font-size: 2em;
        }
      }
      h5 {
        color: #00ecff;
        font-size: 1.7em;
        margin-bottom: 1.4em;
        @media screen and (max-width: 576px) {
          font-size: 1.3em;
          margin-bottom: 1em;
        }
      }
      h6 {
        font-family: ${fonts.default};
        font-weight: bold;
        font-size: 1.25em;
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
        }
      }
      p {
        line-height: 1.5;
        @media screen and (max-width: 768px) {
          margin-right: 0;
          font-weight: normal;
        }

        i {
          color: #00ecff;
        }
      }
    }
  }
`;

export const TicketWrapper = styled.div`
  background-image: url(${d8img});
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
  margin-bottom: 170px;
  @media screen and (max-width: 576px) {
    margin-bottom: 230px;
    padding: 0 !important;
  }
  @media screen and (max-width: 768px) {
    padding: 2em;
  }
  .background {
    background-image: linear-gradient(to right, white 70%, #fff0);
    padding: 4em 2em;
    @media screen and (max-width: 768px) {
      padding: 0px 0 3em;
    }
    @media screen and (max-width: 576px) {
      padding: 0;
    }
  }

  .row {
    display: grid;
    max-width: 950px;
    margin: 0 auto;
    grid-template-columns: 2fr 3fr 1fr;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    justify-items: left;
    align-items: top;

    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      justify-items: center;
    }

    > div:first-child {
      padding-right: 10px;
      > div:nth-child(1) {
        padding-top: 25px;
        font-family: 'Uni Neue';
        font-style: italic;
        font-weight: 800;
        font-size: 35px;
        line-height: 40px;
        text-align: right;
        color: #003863;
        @media screen and (max-width: 576px) {
          font-size: 23px;
          text-align: center;
          line-height: 1.2;
          margin-top: 0px;
        }
      }
      > div:nth-child(2) {
        margin-top: 10px;
        font-family: 'Uni Neue';
        font-style: italic;
        font-weight: 400;
        font-size: 33px;
        line-height: 40px;
        text-align: right;
        color: #02cce6;
        @media screen and (max-width: 576px) {
          font-size: 23px;
          text-align: center;
          line-height: 1.2;
          margin-top: 0px;
        }
      }
    }
  }
  .col {
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: center 0.5em;
    background-size: 94% auto;
    padding: 0.5em 0.7em;
    width: 260px;
    height: 489px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    margin-right: 0.5em;
    &:hover {
      text-decoration: none;
    }
    &.individual {
      background-image: url(${d8ind});
      @media screen and (max-width: 576px) {
        margin-left: 1.3em;
      }
      &:hover {
        filter: brightness(110%);
      }

      &:hover h1 img {
        filter: brightness(110%);
        zoom: 1.1;
      }
      &:hover h1:nth-child(3) {
        margin-top: 35px;
        @media screen and (max-width: 576px) {
          margin-top: 48px;
        }
      }

      &:hover h1:nth-child(1) {
        font-size: 2.3em;
      }

      button {
        background-color: #ffcb05 !important;
        color: #003994 !important;
        font-weight: bold !important;
        font-size: 1em !important;
        font-family: ${fonts.default} !important;
        margin-bottom: 0.5em;
        border: 0;
        padding: 4px 15px;
        text-align: center;
        width: 100%;
        &:hover {
          filter: brightness(105%);
        }

        &:active {
          filter: brightness(110%);
        }
      }
    }
  }
  h1 {
    color: white;
    font-size: 2.5em;
    text-align: center;
    font-family: ${fonts.headings};

    @media screen and (max-width: 576px) {
      font-size: 1.6em;
    }

    &:nth-child(1) {
      margin-top: 35px;
      margin-bottom: 0px;
      font-size: 2.1em;
      font-weight: 500;

      @media screen and (max-width: 576px) {
        font-size: 1.6em;
      }
    }
    &:nth-child(2) {
      display: grid;
      align-items: center;
      justify-items: center;
      img {
        height: 120px;
      }
    }
    &:nth-child(3) {
      margin-top: 50px;
      margin-bottom: 38px;
      font-size: 1.5em;
      font-weight: 400;

      @media screen and (max-width: 576px) {
        font-size: 1.1em;
        margin-top: 73px;
        margin-bottom: 41px;
      }
    }
    &:nth-child(4) {
      color: black;
      font-size: 30px;
      margin: 0;
      display: grid;
      justify-items: center;
      > div {
        width: 112px;
        height: 1px;
        margin-top: 16px;
        border-bottom: 1px solid red;
        -webkit-transform: rotate(-12deg);
        position: absolute;
      }
    }
    &:nth-child(5) {
      font-style: normal;
      margin: 10px 0 25px;
      font-weight: 800;
      font-size: 48px;
      line-height: 19px;
      text-align: center;
      color: #02399b;
    }
  }
  h5 {
    font-family: 'Uni Neue', Roboto, sans-serif;
    font-size: 1.8em;
    font-weight: 900;
    font-style: italic;
    margin: 0 0 1.3em;
    color: #003863;
    max-width: 950px;
    margin: 0 auto 1em;
    @media screen and (max-width: 576px) {
      padding: 1.5rem;
      margin: 0 auto 0;
    }
  }
  @media screen and (min-width: 576px) {
    .tooltip {
      display: inline;
      position: relative;
    }
    .tooltip:hover:before {
      border: solid;
      border-color: transparent #001c4a;
      border-width: 6px 6px 6px 0px;
      bottom: 29px;
      content: '';
      left: 248px;
      position: absolute;
      z-index: 99;
    }
    .tooltip:hover:after {
      background: #001c4a;
      bottom: 9px;
      border-radius: 5px;
      color: #fff;
      content: attr(data-tooltip);
      left: 254px;
      padding: 5px 15px;
      position: absolute;
      z-index: 98;
      width: 100%;
      font-size: 14px;
      text-transform: none;
      font-weight: 200;
      line-height: 1.2;
    }
  }
`;

export const FooterWrapper = styled.footer`
  @keyframes go-right {
    0% {
      transform: translateX(-200px) rotate(45deg);
    }
    90% {
      transform: rotate(45deg);
    }
    100% {
      transform: translateX(0px);
    }
  }
  background: rgb(0, 91, 203);
  background: linear-gradient(68deg, rgba(0, 91, 203, 1) 0%, rgba(0, 29, 100, 1) 100%);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  .countdown {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    color: white;
    padding: 0em;
    margin: 0 auto;
    max-width: 1200px;
    gap: 1rem;
    @media screen and (min-width: 1024px) {
      padding-left: 60px;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 100%;
      padding: 1em;
      grid-gap: 1em;
    }
    @media screen and (max-width: 576px) {
      grid-template-columns: 100%;
      padding: 0.5em;
      grid-gap: 4px;
    }
    h2 {
      font-family: 'Uni Neue';
      font-style: italic;
      font-weight: 800;
      font-size: 22px;
      line-height: 16px;
      justify-self: end;
      text-align: end;
      svg {
        margin-right: 10px;
        animation: go-right 1s;
      }
      @media screen and (max-width: 768px) {
        font-size: 18px;
        justify-self: center;
        text-align: center;
        line-height: 1.1;
      }
      @media screen and (max-width: 1024px) {
        position: relative;
        margin: 0;
      }
      span span {
        color: #ffcb05;
        text-transform: uppercase;
      }
      span div {
        font-size: 16px;
        font-weight: 500;
      }
    }
    a {
      font-size: 1em;
      padding: 9px 15px;
      @media screen and (max-width: 1024px) {
        position: relative;
        font-size: 14px;
        padding: 9px 15px;
      }
      @media screen and (max-width: 576px) {
        margin-top: 15px;
      }
    }
    .btn-footer {
      justify-self: start;
      @media screen and (max-width: 768px) {
        justify-self: center;
        position: relative;
        height: 33px;
        margin-top: 3px;
      }
    }
  }
  .parceiros {
    border-top: 1px solid #80808047;
    background-color: white;
    div {
      height: 50px;
      padding: 0.5em;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-items: center;
      align-items: center;
      margin: 0 auto;
      max-width: 900px;
      @media screen and (max-width: 576px) {
        grid-template-columns: repeat(4, 1fr);
        img:nth-child(1) {
          display: none;
        }
        img {
          max-width: 80%;
        }
      }
      svg {
        height: 70px;
        @media screen and (max-width: 576px) {
          height: 50px;
        }
        @media screen and (max-width: 1200px) {
          width: -webkit-fill-available;
        }
      }
    }
  }
  .tooltip {
    display: inline;
    position: relative;
  }
  @media screen and (min-width: 576px) {
    .tooltip:hover:before {
      border: solid;
      border-color: #001c4a transparent;
      border-width: 6px 6px 0px 6px;
      bottom: 37px;
      content: '';
      left: 10%;
      position: absolute;
      z-index: 99;
    }
    .tooltip:hover:after {
      background: #001c4a;
      bottom: 43px;
      border-radius: 5px;
      color: #fff;
      content: attr(data-tooltip);
      left: 0;
      padding: 5px 15px;
      position: absolute;
      z-index: 98;
      width: 100%;
      font-size: 14px;
      text-transform: none;
      font-weight: 300;
      line-height: 1.2;
    }
  }
`;

export const AlertWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  margin-top: 75px;
  position: fixed;
  top: 0;
  left: 1em;
  right: 1em;
`;
