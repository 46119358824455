import styled from 'styled-components';

export const Link = styled.a`
  background-color: #ffcb05;
  color: #023764;
  text-transform: uppercase;
  text-decoration: none;
  min-height: 48px;
  font-size: 1.2em;
  padding: 16px 20px;
  border-radius: 24px;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: bold;
  line-height: 0.9;
  text-align: center;
  cursor: pointer;
  outline: none;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  &:hover {
    filter: brightness(110%);
  }
  &:active {
    filter: brightness(110%);
  }
`;
export const Button = styled.button`
  background-color: #ffcb05;
  color: #023764;
  text-transform: uppercase;
  min-height: 48px;
  font-size: 1.2em;
  padding: 16px 20px;
  border-radius: 24px;
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: bold;
  text-align: center;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  &:hover {
    filter: brightness(110%);
  }
  &:active {
    filter: brightness(110%);
  }
`;
